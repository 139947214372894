import React from 'react'
import Layout from '../component/Layout'
import { useLocation } from 'react-router-dom'

function RegistrationView() {
    const {state}=useLocation();

  return (
  <Layout>
 <div className="page-content">
  <div className="container-fluid">
    {/* start page title */}
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">Registration View</h4>
     
        </div>
      </div>
    </div>
    {/* end page title */}
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
          
            <div className="table-responsive">
              <table className="table table-striped table-nowrap mb-0">
                <tbody>
                   
                  <tr>
                    <th>Apply For</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-username" data-type="text" data-pk={1} data-title="Enter username">{state?.item?.apply_for}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Event</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-firstname" data-type="text" data-pk={1} data-placement="right" data-placeholder="Required" data-title="Enter your firstname" />
                      {state?.item?.Event[0]?.name}
                    </td>
                  </tr>
                  <tr>
                    <th>Candidate's Name</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-sex" data-type="select" data-pk={1} data-value data-title="Select sex" />
                      {state?.item?.name}
                    </td>
                  </tr>
                  <tr>
                    <th>Candidate's Age</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-dob" data-type="combodate" data-value="2015-09-24" data-format="YYYY-MM-DD" data-viewformat="DD/MM/YYYY" data-template="D / MMM / YYYY" data-pk={1} data-title="Select Date of birth" />
                      {state?.item?.age}
                    </td>
                  </tr>
                  <tr>
                    <th>Candidate's gender</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.gender}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Candidate's Contact No.</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.mobile_no}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Candidate's Email</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.email}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.state}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>District</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.district}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Town</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.town}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Jersey No.</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.jerseyNo}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Jersey Name</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.jerseyName}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Jersey Size</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments">{state?.item?.jerseySize}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Identity</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments"  href={state?.item?.identityId}
                                          target="_blank"
                                          rel="noopener noreferrer">
                
                        View & Download
                        </a>
                    </td>
                  </tr>
                  <tr>
                    <th>Photo</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments"  href={state?.item?.photo}
                                          target="_blank"
                                          rel="noopener noreferrer">
                
                        View & Download
                        </a>
                    </td>
                  </tr>
                  <tr>
                    <th>Resume</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments"  href={state?.item?.resume}
                                          target="_blank"
                                          rel="noopener noreferrer">
                
                        View & Download
                        </a>
                    </td>
                  </tr>
                  <tr>
                    <th>Fees Screenshot</th>
                    <td>
                      <a href="javascript: void(0);" id="inline-comments" data-type="textarea" data-pk={1} data-placeholder="Your comments here..." data-title="Enter comments"  href={state?.item?.feeScreenShot}
                                          target="_blank"
                                          rel="noopener noreferrer">
                
                        View & Download
                        </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> {/* end col */}
    </div> {/* end row */}
  </div> {/* container-fluid */}
</div>

  </Layout>

  )
}

export default RegistrationView