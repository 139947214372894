import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Login() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const BASE_URL = process.env.REACT_APP_BASE_URL;
  
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }
    const submit = (e) => {
      const obj = {
        email: email,
        role: "2",
        password: password,
      };
  
      if (email.length > 100) {
        toast.error("please enter valid email address length ");
      } else {
        if (isValidEmail(email)) {
          setEmail(email);
        } else {
          toast.error("please enter valid email address");
        }
      }
  
      if (password.length > 6) {
        toast.error("password must be upto 6 digit ");
      } else if (password.length <= 3) {
        toast.error("password must be of minimum 4 digit");
      }
      axios
        .post(`${BASE_URL}admin/adminLogin`, obj, {
          headers: {
            version: "1.0.0",
            "Content-type": "Application/json",
          },
        })
        .then(function (response) {
          if (response.data.success) {
            localStorage.setItem("User", response.data.data);
            toast.success(response.data.message);
            localStorage.setItem("token", response.data.data.token);
            navigate("/");
          } else {
            toast.error(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  
 
  return (
<div className="account-pages my-5 pt-sm-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-5">
        <div className="card overflow-hidden">
          <div className="bg-primary bg-soft">
            <div className="row">
              <div className="col-7">
                <div className="text-primary p-4">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to admin.</p>
                </div>
              </div>
              <div className="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="card-body pt-0"> 
            <div className="auth-logo">
              <a href="index.html" className="auth-logo-light">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo-light.svg" alt className="rounded-circle" height={34} />
                  </span>
                </div>
              </a>
              <a href="index.html" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.svg" alt className="rounded-circle" height={34} />
                  </span>
                </div>
              </a>
            </div>
            <div className="p-2">
            
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Username</label>
                  <input type="text" className="form-control" id="username" placeholder="Enter username" 
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <div className="input-group auth-pass-inputgroup">
                    <input type="password" className="form-control" placeholder="Enter password" aria-label="Password" 
                    name='password'
                    onChange={(e)=>setPassword(e.target.value)}
                    aria-describedby="password-addon" />
                    <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"  onClick={()=>submit()}/></button>
                  </div>
                </div>
                {/* <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="remember-check" />
                  <label className="form-check-label" htmlFor="remember-check">
                    Remember me
                  </label>
                </div> */}
                <div className="mt-3 d-grid">
                  <button className="btn btn-primary waves-effect waves-light" onClick={()=>submit()}>Log In</button>
                </div>
                {/* <div className="mt-4 text-center">
                  <h5 className="font-size-14 mb-3">Sign in with</h5>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="javascript::void()" className="social-list-item bg-primary text-white border-primary">
                        <i className="mdi mdi-facebook" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="javascript::void()" className="social-list-item bg-info text-white border-info">
                        <i className="mdi mdi-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="javascript::void()" className="social-list-item bg-danger text-white border-danger">
                        <i className="mdi mdi-google" />
                      </a>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="mt-4 text-center">
                  <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot your password?</a>
                </div> */}
            
            </div>
          </div>
        </div>
        {/* <div className="mt-5 text-center">
          <div>
            <p>Don't have an account ? <a href="auth-register.html" className="fw-medium text-primary"> Signup now </a> </p>
            <p>©  Skote. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesbrand</p>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</div>

  )
}

export default Login