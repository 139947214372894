import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/Layout";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
function AddImage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const fileInputRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [imageCategoryList, setImageCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const addImage = ({ event }) => {
    setFile(event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(event.target.files[0]);
      setImage(newImageSrc);
      console.log(newImageSrc);
    }
  };

  const AddUpdateimageData = async () => {
    const fd = new FormData();
    let api = "addImage";
    if (state?.item?._id != undefined) {
      api = "updateImage";
    }
    fd.append("image", file[0]);
    if (state?.item?._id != "") {
      fd.append("id", state?.item?._id);
    }
    fd.append("categoryId", categoryId);

    let token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/image-gallery-list");
        // window.location.href = '/event-list';
        return response.data;
      } else {
        if (response.data.error_code === 461) {
          navigate("/login");
        }
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  const imageCategoryData = async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}admin/getImageCategory`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            version: "1.0.0",
          },
        }
      );
      if (response.data.success) {
        setImageCategoryList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);

        if (response.data.error_code == 461) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        // toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    if (state) {
      setFile(state?.item?.image);
      setImage(state.item.image);
    }
    imageCategoryData();
  }, []);
  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Gallery Image </h5>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          Category
                        </label>
                        <select
                          className="form-control p-3"
                          onChange={(e) => setCategoryId(e.target.value)}
                          value={categoryId}
                          placeholder="--Select Category--"
                        >
                           <option value={""}>
                           --Select Category--
                              </option>
                          {
                            imageCategoryList.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))
                          }
                         
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          Upload Image
                        </label>
                        <input
                          className="form-control p-3"
                          type="file"
                          ref={fileInputRef}
                          id="formFile"
                          onChange={(e) => addImage({ event: e })}
                        />
                      </div>
                      <br />

                      {image != "" && (
                        <div className="">
                          {/* <div className="card-body"> */}
                          <div>
                            <form
                              action="#"
                              className="dropzone  card card-body"
                              style={{ width: "150px", minHeight: "120px" }}
                            >
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  {image != "" ? (
                                    <img
                                      src={image}
                                      className="img-fluid"
                                      alt="Uploaded"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </div>{" "}
                    {/* end col */}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={() => AddUpdateimageData()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddImage;
