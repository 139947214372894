import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addPost } from "./NewPostAPI";
import { GetEvents } from "../Event/eventApi";

import { toast } from "react-toastify";
import moment from "moment";
const initialState = {
  title: "",
  image: "",
  vedio: "",
  date: "",
  time: "",
  status: "Active",
  postType: "",
  _id: "",
  eventId: "",
};
function NewPost() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [values, setValues] = useState(initialState);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setValues({
        ...values,
        image: [...values.image, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const addImage = ({ event }) => {
    setFile(event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(event.target.files[0]);
      setImage(newImageSrc);
      console.log(newImageSrc);
    }
  };
  const AddUpdatePostData = async () => {
    if (!values.eventId || values.eventId == null) {
      toast.error("Please select Event");
      return;
    }
    const fd = new FormData();
    let api = "addPosts";
    if (values._id != "") {
      api = "updatePost";
    }
    fd.append("title", values.title);
    if (file.length > 0) {
      fd.append("image", file[0]);
    }
    fd.append("date", values.date);
    fd.append("time", values.time);
    fd.append("description", description);
    fd.append("status", values.status);
    fd.append("postType", values.postType);
    fd.append("eventId", values.eventId);

    fd.append("video", values.video);

    if (values._id != "") {
      fd.append("id", values._id);
    }
    try {
      let data = await addPost({ api: api, formdata: fd });
      if (data.success) {
        navigate("/post-list");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const EventData = async () => {
    try {
      let data = await GetEvents({ api: "getEvent", pagination: {} });
      setEvents(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      setImage(state.item.image);
      setDescription(state.item.description);
    }
    EventData();
  }, []);

  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">New Post </h5>
                  <div class="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      name="eventId"
                      value={values?.eventId}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option selected>Select Even </option>
                      {events?.map((event) => (
                        <option key={event._id} value={event._id}>
                          {event?.name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelectGrid">Event</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter Title"
                      name="title"
                      value={values?.title}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="floatingnameInput">Title</label>
                  </div>

                  <div class="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      name="postType"
                      value={values?.postType}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option selected>Select Post Type</option>
                      <option value="Image">Image</option>
                      <option value="Video">Video</option>
                      <option value="Text">Text</option>
                    </select>
                    <label for="floatingSelectGrid">Post Type</label>
                  </div>

                  {values?.postType == "Video" && (
                    <>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Enter Video URL"
                          name="video"
                          value={values.video}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingnameInput">
                          Enter Video URL
                        </label>
                      </div>
                    </>
                  )}
                  {values?.postType == "Image" && (
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            Upload Image
                          </label>
                          <input
                            className="form-control p-3"
                            type="file"
                            ref={fileInputRef}
                            id="formFile"
                            onChange={(e) => addImage({ event: e })}
                          />
                        </div>
                        <br />

                        {image != "" && (
                          <div className="">
                            {/* <div className="card-body"> */}
                            <div>
                              <form
                                action="#"
                                className="dropzone  card card-body"
                                style={{ width: "150px", minHeight: "120px" }}
                              >
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    {image != "" ? (
                                      <img
                                        src={image}
                                        className="img-fluid"
                                        alt="Uploaded"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/* </div> */}
                          </div>
                        )}
                      </div>{" "}
                      {/* end col */}
                    </div>
                  )}
                  <label htmlFor="">Enter Description</label>
                  <div className="form-floating mb-3">
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(e, editor) => {
                        let data = editor.getData();
                        setDescription(data);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                   
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="date"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Time"
                          name="date"
                          value={moment(values?.date).format("YYYY-MM-DD")}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingemailInput">Enter Date</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="time"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Time "
                          name="time"
                          value={values?.time}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingemailInput">Time</label>
                      </div>
                    </div>
                  </div>
             
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={() => AddUpdatePostData()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NewPost;
