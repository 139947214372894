import React from 'react'
// import MegaMenu from '../component/MegaMenu'
import Menu from '../component/Menu'
import Layout from '../component/Layout'

function Dashboard() {
  return (
  <Layout>
  <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                <div className="page-title-right">
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Orders</p>
                          <h4 className="mb-0">1,235</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-copy-alt font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Revenue</p>
                          <h4 className="mb-0">$35, 723</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center ">
                          <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className="bx bx-archive-in font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Average Price</p>
                          <h4 className="mb-0">$16.2</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className="bx bx-purchase-tag-alt font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
  </Layout>
 

   
  )
}

export default Dashboard