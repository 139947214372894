import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
function AddVideo() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const AddUpdateVideoData = async () => {
    let obj = {};
    let api = "addVideo";
    if (state?.item?._id != undefined) {
      api = "updateVideo";
    }
    obj.video = file;
    if (state?.item?._id != "") {
      obj.id = state?.item?._id;
    }
    let token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}admin/${api}`, obj, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/video-gallery-list");
        // window.location.href = '/event-list';
        return response.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    if (state) {
      setFile(state?.item?.video);
    }
  }, []);
  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Video </h5>

                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          Add Video Url
                        </label>
                        <input
                          className="form-control p-3"
                          type="text"
                          placeholder="Enter Video Url"
                          value={file}
                          id="formFile"
                          onChange={(e) => setFile(e.target.value)}
                        />
                      </div>
                      <br />
                    </div>{" "}
                    {/* end col */}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={() => AddUpdateVideoData()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddVideo;
