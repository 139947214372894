import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addEventDetails } from "./EventDetailsAPI";
import { GetEvents } from "../Event/eventApi";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
const initialState = {
  date: "",
  status: "Active",
  _id: "",
  eventId: "",
};
function AddEventDetails() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [eventId, setEventId] = useState("");
  const [events, setEvents] = useState([]);
  const [values, setValues] = useState(initialState);
  const [details, setDetails] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const AddUpdateEventDetails = async () => {
    let api = "addEventDetails";
    //  console.log("API", values.eventId);
    //  return;
    let obj = {
      eventId: eventId,
      status: values.status,
      date: values.date,
      details: details,
    };
    // console.log("values",values._id);
    if (values._id !== undefined) {
      obj.id = values._id;
      api = "updateEventDetails";
    }
    try {
      let data = await addEventDetails({ api: api, formdata: obj });
      if (data.success) {
        // navigate("/event-detail-list");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const EventData = async () => {
    try {
      let data = await GetEvents({ api: "getEvent", pagination: {} });
      setEvents(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      setDetails(state.item.details);
    }
    EventData();
  }, []);
  useEffect(() => {
    if (values.eventId) {
      setEventId(values.eventId);
      const token = localStorage.getItem("token");
      axios
        .post(
          `${BASE_URL}admin/getEventDetailById/${values.eventId}`,
          {},
          {
            headers: {
              "Content-Type": "Application/json",
              "x-access-token": `${token}`,
              version: "1.0.0",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setValues({ ...response.data.data });
            setDetails(response.data.data.details);
          } else {
            // toast.error("Error in fetching event details");
            setValues({});
            setDetails("");
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [values.eventId]);

  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-3">Add/Edit Event Details </h5>
                  <div class="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                      name="eventId"
                      value={values.eventId}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option selected>Select Even </option>
                      {events?.map((event) => (
                        <option key={event._id} value={event._id}>
                          {event.name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelectGrid">Event</label>
                  </div>

                  <label htmlFor="">Enter Description</label>
                  <div className="form-floating mb-3">
                    <CKEditor
                      editor={ClassicEditor}
                      data={details}
                      onReady={(editor) => {
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(e, editor) => {
                        let data = editor.getData();
                        setDetails(data);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                      config={{
                        height: 500, // height in pixels
                      }}
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="date"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Time"
                          name="date"
                          value={moment(values.date).format("YYYY-MM-DD")}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingemailInput">Enter Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="floatingCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="floatingCheck"
                      >
                        Check me out
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={() => AddUpdateEventDetails()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddEventDetails;
