import React, { useEffect, useState } from "react";
import Layout from "../../component/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function VideoGalleryList() {
  const [page, setPage] = useState(1);
  const [videoList, setVideoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const videoData = async () => {
    const obj = {
      page: page,
      limit: 8,
    };
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}admin/getVideo`, obj, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        setVideoList(response.data.data);
      setLoading(false);

      } else {
      setLoading(false);

        if (response.data.error_code == 461) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        // toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };
  const deleteVideo = async (id) => {
    let token = localStorage.getItem("token");
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    try {
      const response = await axios.delete(
        `${BASE_URL}admin/deleteVideo/${id}`,
        {
          headers: {
            version: "1.0.0",
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
          },
        }
      );
      if (response.data.success) {
        toast.error(response.data.message);
        setVideoList([]);
        videoData();

      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    videoData();
  }, []);

  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Video Gallery List</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : videoList?.length > 0 ? (
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>S. No</th>
                          <th>Video</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {videoList?.map((item, index) => (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>
                              {item?.video!="" ? (
                                <iframe
                                  width="150"
                                  height="120"
                                  src={`${item?.video}`}
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  referrerPolicy="strict-origin-when-cross-origin"
                                  allowFullScreen
                                ></iframe>
                              ) : (
                                <p>Video not available</p>
                              )}
                            </td>

                            <td>
                              <div>
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() =>
                                    navigate("/add-video", { state: { item } })
                                  }
                                >
                                  <i className="fas fa-pencil-alt" />
                                </div>
                                &nbsp;&nbsp;
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() => deleteVideo(item._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <h2>No Record Found </h2>
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
        </div>{" "}
        {/* container-fluid */}
      </div>
    </Layout>
  );
}

export default VideoGalleryList;
