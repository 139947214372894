import React, { useEffect, useRef, useState } from "react";
import Layout from "../../component/Layout";
import { addEvent } from "./eventApi";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
  name: "",
  titleText: "",
  altText: "",
  description: "",
  image: "",
  order:"",
  status: "Active",
  _id: "",
};
function AddEvent() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setValues({
        ...values,
        image: [...values.image, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const addImage = ({ event }) => {
    setFile(event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const newImageSrc = URL.createObjectURL(event.target.files[0]);
      setImage(newImageSrc);
      console.log(newImageSrc);
    }
  };
  const AddUpdateEventData = async () => {
    const fd = new FormData();
    let api = "addEvent";
    if (values._id != "") {
      api = "updateEvent";
    }
    fd.append("name", values.name);
    fd.append("altText", values.altText);
    fd.append("titleText", values.titleText);
    fd.append("order", values.order);

    fd.append("image", file[0]);
    fd.append("description", values.description);
    fd.append("status", values.status);
    if (values._id != "") {
      fd.append("id", values._id);
    }
    try {
      let data = await addEvent({ api: api, formdata: fd });
      setValues({ ...initialState });
      if (data.success) {
        navigate("/event-list");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (state) {
      setValues({ ...state.item });
      setImage(state.item.image);
    }
  }, []);
  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Event </h5>
              
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter Name"
                      name="name"
                      value={values.name}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="floatingnameInput">Name</label>
                  </div>

             
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          Upload Image
                          <br />
                          <span className="text-danger">Size: 100 X 100</span>
                        </label>
                        <input
                          className="form-control p-3"
                          type="file"
                          ref={fileInputRef}
                          id="formFile"
                          onChange={(e) => addImage({ event: e })}
                        />
                      </div>
                      <br />

                      {image != "" && (
                        <div className="">
                          <div>
                            <form
                              action="#"
                              className="dropzone  card card-body"
                              style={{ width: "150px", minHeight: "120px" }}
                            >
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  {image != "" ? (
                                    <img
                                      src={image}
                                      className="img-fluid"
                                      alt="Uploaded"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </div>{" "}
                    {/* end col */}
                  </div>


                  <div className="row">
                  <div className="col-md-6">
                         <div className="form-floating mb-3">
                      <input className="form-control" id="floatingnameInput" 
                      type="number"
                      placeholder="Enter Order"
                      name='order'
                     
                      value={values.order}
                      onChange={(e) => handleInputChange(e)}
                      />
                      <label htmlFor="floatingnameInput">Enter Order </label>
                    </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Email address"
                          name="altText"
                          value={values.altText}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingemailInput">
                          Enter Image Alt Text
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Image Title "
                          name="titleText"
                          value={values.titleText}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="floatingemailInput">
                          Enter Image Title Text
                        </label>
                      </div>
                    </div>
                  </div>
             
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={() => AddUpdateEventData()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddEvent;
