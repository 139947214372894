import React, { useState } from 'react'
import Menu from './Menu'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo1 from "../images/logo.jpeg"

function Layout({children}) {
  const [toggle, setToggle]=useState(false);
    const navigate= useNavigate();
    const LogOut=()=>{
        localStorage.removeItem('token');
        navigate('/login');
      }

      const toggleMenu = () => {
        const body = document.getElementsByTagName('body')[0];
        
        if (!toggle) {
          body.classList.add("sidebar-enable", "vertical-collpsed");
        } else {
          body.classList.remove("sidebar-enable" ,"vertical-collpsed");
        }
        
        setToggle(!toggle);
      };

   
      
  return (
 
     <div>
  <div id="layout-wrapper">
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          {/* LOGO */}
          <div className="navbar-brand-box">
            <Link to={'/dashboard'} className="logo logo-dark">
              <span className="logo-sm">
              <img
                        src={logo1}
                        className="img-fluid"
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
              </span>
              <span className="logo-lg">
              <img
                        src={logo1}
                        className="img-fluid"
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
              </span>
            </Link>
            <Link to={'/dashboard'} className="logo logo-light">
              <span className="logo-sm">
              <img
                        src={logo1}
                        className="img-fluid"
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
              </span>
              <span className="logo-lg">
              <img
                        src={logo1}
                        className="img-fluid"
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
              </span>
            </Link>
          </div>
          <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars" onClick={()=>toggleMenu()} />
          </button>
        </div>
        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="mdi mdi-magnify" />
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify" /></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
      
      
          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <i className='fa fa-user'></i>
              <span className="d-none d-xl-inline-block ms-1" key="t-henry">Admin</span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {
                localStorage.getItem("token")?
                <button  className="dropdown-item text-danger" href="#"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> <span key="t-logout" onClick={()=>LogOut()}>Logout</span></button>:
                
                <button className="dropdown-item text-danger" href="#"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> <span key="t-logout">Login</span></button>


              }
            </div>
          </div>
          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
              <i className="bx bx-cog bx-spin" />
            </button>
          </div>
        </div>
      </div>
    </header>
  
<Menu/>


    <div className="main-content">
     
    {children}
  
      {/* end modal */}
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              © @024.
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design &amp; Develop by <a href='https://itsgwalior.com/'>itsgwalior.com</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    {/* end main content*/}
  </div>

</div>

 
  )
}

export default Layout