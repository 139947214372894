import React, { useEffect, useState } from "react";
import Layout from "../../../component/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function ImageCategoryList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
  const [imageCategoryList, setImageCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const imageCategoryData = async () => {
    const obj = {
      page: currentPage,
      limit: 8,
    };
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}admin/getImageCategory`, obj, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      });
      if (response.data.success) {
        setImageCategoryList(response.data.data);
        setPages(response.data.remainingCount);
        setLoading(false);

      } else {
        setLoading(false);

        if (response.data.error_code == 461) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        // toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };
  const deleteImageCategory = async (id) => {
    let token = localStorage.getItem("token");
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    try {
      const response = await axios.delete(
        `${BASE_URL}admin/deleteImageCategory/${id}`,
        {
          headers: {
            version: "1.0.0",
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
          },
        }
      );
      if (response.data.success) {
        toast.error(response.data.message);
        setImageCategoryList([]);
        imageCategoryData();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    imageCategoryData();
  }, [currentPage]);

  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Image Category List</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : imageCategoryList?.length > 0 ? (
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>S. No</th>
                          <th>Category</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {imageCategoryList?.map((item, index) => (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>
                             {item.name}
                            </td>

                            <td>
                              <div>
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() =>
                                    navigate("/add-image-category", { state: { item } })
                                  }
                                >
                                  <i className="fas fa-pencil-alt" />
                                </div>
                                &nbsp;&nbsp;
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() => deleteImageCategory(item._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <h2>No Record Found </h2>
                    </div>
                  )}
                </div>
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>{" "}
            {/* end col */}
          </div>{" "}
        </div>{" "}
        {/* container-fluid */}
      </div>
    </Layout>
  );
}

export default ImageCategoryList;
