import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Menu() {
  const navigate= useNavigate();

  useEffect(()=>{
    if(!localStorage.getItem('token')){
      navigate("/login")
    }
  },[])
  return (
    <div className="vertical-menu">
    <div data-simplebar className="h-100">
      {/*- Sidemenu */}
      <div id="sidebar-menu">
        {/* Left Menu Start */}
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title" key="t-menu">Menu</li>
          <li onClick={()=>navigate("/dashboard")}>
            <a href="javascript: void(0);" className="waves-effect">
              <i className="bx bx-home-circle" />
              {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
              <span key="t-dashboards">Dashboards</span>
            </a>
  
          </li>
           
          <li>
            
            <a href="javascript: void(0);" className="has-arrow waves-effect">
              <i className="bx bx-store" />
              <span key="t-ecommerce">Event</span>
            </a>
            <ul className="sub-menu" aria-expanded="false">
              <li><Link  key="t-products" to="/add-event">Add Event</Link></li>
              <li><Link key="t-product-detail" to="/event-list">Event List</Link></li>
              <li><Link  key="t-products" to="/add-edit-event-details">Add/Edit Event Details</Link></li>
            </ul>
          </li>
          <li>
            <a href="javascript: void(0);" className="has-arrow waves-effect">
              <i className="bx bx-store" />
              <span key="t-ecommerce"> Post</span>
            </a>
            <ul className="sub-menu" aria-expanded="false">
              <li><Link  key="t-products" to="/">New Post</Link></li>
              <li><Link key="t-product-detail" to="/post-list">Post List</Link></li>
            </ul>
          </li>
      
          <li>
            
            <a href="javascript: void(0);" className="has-arrow waves-effect">
              <i className="bx bx-store" />
              <span key="t-ecommerce">Image Gallery</span>
            </a>
            <ul className="sub-menu" aria-expanded="false">
            <li><Link  key="t-products" to="/add-image-category">Add Image Catgeory</Link></li>
            <li><Link  key="t-products" to="/image-category-list">Image Catgeory List</Link></li>

              <li><Link  key="t-products" to="/add-image">Add Image</Link></li>
              <li><Link key="t-product-detail" to="/image-gallery-list">Image Gallery List</Link></li>
              <li><Link  key="t-products" to="/add-video">Add Video</Link></li>
              <li><Link key="t-product-detail" to="/video-gallery-list">Video Gallery List</Link></li>
            </ul>
          </li>
        
       
      
          <li onClick={()=>navigate("/registration-list")}>
            <a href="javascript: void(0);" className="waves-effect">
              <i className="bx bx-home-circle" />
              <span key="t-dashboards">Registration List</span>
            </a>
          </li>
          <li onClick={()=>navigate("/contact-list")}>
            <a href="javascript: void(0);" className="waves-effect">
              <i className="bx bx-home-circle" />
              <span key="t-dashboards">Contact List</span>
            </a>
          </li>
        </ul>
      </div>
      {/* Sidebar */}
    </div>
  </div>
  )
}

export default Menu