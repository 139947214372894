import React, { useEffect, useState } from "react";
import Layout from "../../component/Layout";
import { GetPosts, DeletePosts } from "./NewPostAPI";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

function PostList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState([]);
  const navigate = useNavigate();

  const PostData = async () => {
    const obj = {
      page: currentPage,
      limit: 8,
    };
    try {
      setLoading(true);
      let data = await GetPosts({ api: "getPosts", pagination: obj });
      setPosts(data.data);
      setPages(data.remainingCount);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const deletePost = async (id) => {
    try {
      if (!window.confirm("Do you really want to delete this item?")) {
        return;
      }
      await DeletePosts({ api: "deletePost", _id: id });
      PostData()
    } catch (error) {
      console.log(error.message);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    PostData();
  }, [currentPage]);

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <Layout>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Post List</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : posts?.length > 0 ? (
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>S. No</th>
                          <th>Title</th>
                          <th>Video</th>
                          <th>Image</th>
                          <th>Description</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {posts?.map((item, index) => (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?.title}</td>
                            <td>
                              {item?.video!=""&&item?.video!="undefined" ? (
                                <iframe
                                  width="100"
                                  height="80"
                                  src={`${item?.video}`}
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  referrerPolicy="strict-origin-when-cross-origin"
                                  allowFullScreen
                                ></iframe>
                              ) : (
                                <p>Video not available</p>
                              )}
                            </td>

                            <td>
                             {
                              item.image!=""?
                              <img
                              src={item?.image}
                              alt="Image"
                              style={{ width: "50px", height: "50px" }}
                            />:<p>Image not available</p>
                             }
                            </td>
                            <td>{renderHTML(item?.description)}</td>
                            <td>{moment(item?.date).format('YYYY-MM-DD')}</td>
                            <td>{item?.time}</td>

                            <td>
                              <div>
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() =>
                                    navigate("/", { state: { item } })
                                  }
                                >
                                  <i className="fas fa-pencil-alt" />
                                </div>
                                &nbsp;&nbsp;
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() => deletePost(item._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <h2>No Record Found </h2>
                    </div>
                  )}
                </div>
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>{" "}
            {/* end col */}
          </div>{" "}
        </div>{" "}
        {/* container-fluid */}
      </div>
    </Layout>
  );
}

export default PostList;
