import React from 'react'
import  { useEffect, useRef, useState } from 'react'
import Layout from '../../../component/Layout'

import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
function AddImageCategory() {

      const {state}=useLocation();
      const navigate = useNavigate();
      const [name, setName] = useState('');
      const BASE_URL= process.env.REACT_APP_BASE_URL;

        const AddUpdateimageCategoryData = async () => {
            let obj={
                name:name
            }
        let api = "addImageCategory";
        if (state?.item?._id!=undefined) {
          api = "updateImageCategory";
        }
        
        
        if(state?.item?._id!=""){
        obj.id=state?.item?._id;
        }
        let token =localStorage.getItem('token');
        try {
          const response = await axios.post(`${BASE_URL}admin/${api}`,obj,{
              headers:{
                  'Content-Type': 'Application/json',
                  'x-access-token':`${token}`,
                  version:'1.0.0'
              }
          });
          if(response.data.success){
              toast.success(response.data.message);
              navigate("/image-category-list")
              // window.location.href = '/event-list';
              return response.data;
          }else{
            if(response.data.error_code===461){
              navigate("/login");
            }
              toast.error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          // throw error;
        }
          };
    
          useEffect(()=>{
            if(state){
              setName(state?.item?.name)
            }
          },[])
      return (
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Add Category </h5>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Enter category
                            </label>
                            <input
                              className="form-control p-3"
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              placeholder="Enter Category"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          onClick={() => AddUpdateimageCategoryData()}
                        >
                          Submit
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
}

export default AddImageCategory