import { useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";  import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';import { Suspense } from "react";
import { ThreeCircles } from "react-loader-spinner";
import './App.css';
import Dashboard from './Pages/Dashboard';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AddEvent from './Pages/Event/AddEvent';
import EventList from './Pages/Event/EventList';
import Login from './Pages/Auth/Login';
import NewPost from './Pages/CreatePost/NewPost';
import PostList from './Pages/CreatePost/PostList';
import AddEventDetails from './Pages/Event Details/AddEventDetails';
import RegistrationList from './Pages/RegistrationList';
import RegistrationView from './Pages/RegistrationView';
import ContactList from './Pages/ContactList';
import AddImage from './Pages/image gallery/AddImage';
import ImageGalleryList from './Pages/image gallery/ImageGalleryList';
import AddVideo from './Pages/Video Gallery/AddVideo';
import VideoGalleryList from './Pages/Video Gallery/VideoGalleryList';
import ImageCategoryList from './Pages/image gallery/Image Category/ImageCategoryList';
import AddImageCategory from './Pages/image gallery/Image Category/AddImageCategory';

function App() {
  const router = createBrowserRouter([
    {
   path:"/",
   element:<NewPost/>
    },
    {
      path:"/dashboard",
      element:<Dashboard/>
       },
    {
      path:"/add-event",
      element:<AddEvent/>
    }, {
      path:"/event-list",
      element:<EventList/>
    }, 
    //  {
    //   path:"/new-post",
    //   element:<NewPost/>
    // }, 
     {
      path:"/post-list",
      element:<PostList/>
    }, {
      path:"/add-edit-event-details",
      element:<AddEventDetails/>
    },{
      path:"/registration-list",
      element:<RegistrationList/>
    },{
      path:"/registration-view",
      element:<RegistrationView/>
    },{
      path:"/contact-list",
      element:<ContactList/>
    },{
      path:"/add-image",
      element:<AddImage/>
    },{
      path:"/image-gallery-list",
      element:<ImageGalleryList/>
    },{
      path:"/add-video",
      element:<AddVideo/>
    },{
      path:"/video-gallery-list",
      element:<VideoGalleryList/>
    },{
      path:"/login",
      element:<Login/>
    },{
      path:"/image-category-list",
      element:<ImageCategoryList/>
    },{
      path:"/add-image-category",
      element:<AddImageCategory/>
    }
  ])
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/custom-jquery.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Suspense
    fallback={
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <ThreeCircles
          height="80"
          width="80"
          radius="9"
          color="black"
          ariaLabel="three-dots-loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    }
  >
    <ToastContainer />

    <RouterProvider router={router} />
  </Suspense>
  );
}

export default App;
