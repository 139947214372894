import axios from 'axios';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addPost = async (obj) => {

    let token =localStorage.getItem('token');
  try {
    const response = await axios.post(`${BASE_URL}admin/${obj.api}`,obj.formdata,{
        headers:{
            'Content-Type': 'multipart/form-data',
            'x-access-token':`${token}`,
            version:'1.0.0'
        }
    });
    if(response.data.success){
        toast.success(response.data.message);
        // window.location.href = '/event-list';
        return response.data;
    }else{
        toast.error(response.data.message);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    // throw error;
  }
};

export const GetPosts = async (obj) => {
    let token =localStorage.getItem('token');
  try {
    const response = await axios.post(`${BASE_URL}admin/${obj.api}`,obj.pagination,{
        headers:{
            'Content-Type': 'Application/json',
            'x-access-token':`${token}`,
            version:'1.0.0'
        }
    });
    if(response.data.success){
        // toast.success(response.data.message);
        return response.data;
    }else{
        if(response.data.error_code==461){
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
        toast.error(response.data.message);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    // throw error;
  }
};


export const DeletePosts = async (obj) => {
  let token =localStorage.getItem('token');
try {
  const response = await axios.delete(`${BASE_URL}admin/${obj.api}/${obj._id}`,{
      headers:{
          version:'1.0.0',
          'Content-Type': 'Application/json',
          'x-access-token':`${token}`,
      }
  });
  if(response.data.success){
      toast.error(response.data.message);
  }
} catch (error) {
  console.error('Error fetching user data:', error);
  // throw error;
}
};



// Other API functions
